import { Injectable, OnDestroy, inject } from "@angular/core";
import { MenuItem } from "primeng/api";
import { TenantModel } from "src/app/core/models/global/clients/tenant-model";
import { AssetModel } from "src/app/core/models/tenant/location/asset-model";
import { OptixComponentBase } from "src/app/core/utils/base-components/optix-component-base";
import { MenuBase, OptixMenuItem } from "./menu-base";
import { Subscription } from "rxjs";
import { AppOktaSessionService } from "../app-okta-session.service";
import { OptixSettingName } from "src/app/core/models/constants/optix-setting-name.constants";

@Injectable({
    providedIn: 'root'
})
export class MenuService extends OptixComponentBase implements OnDestroy {

    private appOktaSessionService: AppOktaSessionService = inject(AppOktaSessionService);

    public tenant?: TenantModel;
    public asset?: AssetModel;
    public roles: string[] = [];

    private sessionSubscription!: Subscription;

    constructor(private menuBase: MenuBase) {
        super();

        this.sessionSubscription = this.appOktaSessionService.sessionState$().subscribe(sessionState => {
            this.tenant = sessionState.currentTenant;
            this.asset = sessionState.currentAsset;
            this.roles = sessionState.roles;
        });
    }

    ngOnDestroy(): void {
        // End the subscription to the session
        if (this.sessionSubscription) {
            this.sessionSubscription.unsubscribe();
        }
    }

    /**
     * The menu items for the user.
     * @returns Collection of menu items
     */
    public getMenuItems(command?: any): MenuItem[] {
        // Build the optix menu
        return this.processOptixMenu(this.menuBase.fullMenu, command);
    }

    private processOptixMenu(optixMenuItems: OptixMenuItem[], command?: any): MenuItem[] {
        var userSpecificMenu: MenuItem[] = [];
        // Only attempt to build the menu if the roles have been initialised.
        if (this.roles !== undefined) {
            // Get all the menu items that the user has the roles for
            optixMenuItems.forEach((optixMenuItem) => {
                let validatedMenuItem = this.validateMenuItemAccess(optixMenuItem, command);
                if (validatedMenuItem)
                    userSpecificMenu.push(validatedMenuItem);
            });
        }

        return userSpecificMenu;
    }

    /**
     * Validates the user has access to the menu item
     * @param optixMenuItem The menu item to check the user has access to
     * @param roles The roles that the user has
     */
    private validateMenuItemAccess(optixMenuItem: OptixMenuItem, command?: any): MenuItem | undefined {
        // If the menu item has a route specified, check the permissions for that route
        if (optixMenuItem.route.length > 0) {
            if (optixMenuItem.role === '' || this.roles.includes(optixMenuItem.role)) 
                return this.convertOptixMenuItemToPrimeNgMenuItem(optixMenuItem, command);
        }
        else if (optixMenuItem.isExternal) {
            if (optixMenuItem.role === '' || this.roles.includes(optixMenuItem.role)) 
                return this.convertOptixMenuItemToPrimeNgMenuItem(optixMenuItem, command);
        }
        else {
            // The menu item has child menu items, so convert each of the child menu items and then create a
            // new primary menu item
            var childMenu: MenuItem[] = [];
            if (optixMenuItem.subMenu)
                childMenu = this.processOptixMenu(optixMenuItem.subMenu, command);
            // Create the menu itme with its child menu items
            if (childMenu && childMenu.length > 0)
                return this.convertOptixMenuItemToPrimeNgMenuItem(optixMenuItem, command, childMenu);
        }

        return undefined;
    }

    /**
     * Converts the optix style menu item to a PrimeNG menu item.
     * @param optixMenuItem The optix menu item to be converted.
     */
    public convertOptixMenuItemToPrimeNgMenuItem(optixMenuItem: OptixMenuItem, command?: any, childMenu?: MenuItem[]): MenuItem {
        let routerLink = (optixMenuItem.route.length > 0) ? optixMenuItem.route : undefined;
        // Make replacements of the route params
        if (routerLink) {
            if (this.tenant)
                routerLink = routerLink.replace(":tenant", this.tenant.tenancyName);

            if (this.asset)
                routerLink = routerLink.replace(":asset", this.asset.name);
        }

        return {
            label: optixMenuItem.name,
            //icon: optixMenuItem.icon ? `fas fa-fw ${optixMenuItem.icon}` : '',
            routerLink: (routerLink ? [routerLink] : undefined),
            items: ((childMenu && childMenu.length > 0) ? childMenu : undefined),
            command: (
                (routerLink) 
                    ? command
                    : (optixMenuItem.isExternal)
                        ? (event) => {
                            this.loadExternalUrl(event, optixMenuItem.externalUrlSettingName);
                            command();
                        }
                        : undefined
            )
        };
    }

    /**
     * Gets the external url from the app settings for the asset and opens the url in a new tab
     */
    public loadExternalUrl(event: any, externalUrlSettingName: string) {
        this.logDebug(this.loadExternalUrl.name, 'launching external url', [event, externalUrlSettingName]);
        // Get the setting and launch the url in a new tab
        let url = this.tenant?.tenantSettingModels.find(e => e.name === OptixSettingName.UserManualUrl);
        url = this.asset?.assetSettingModels.find(e => e.name === externalUrlSettingName);
        if (url)
            window.open(url.value, '_blank');
    }
}