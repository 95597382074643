import { Inject, Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable, lastValueFrom } from "rxjs";
import OktaAuth, { IDToken } from "@okta/okta-auth-js";
import { OKTA_AUTH, OktaAuthStateService } from "@okta/okta-angular";
import { OptixComponentBase } from "../../utils/base-components/optix-component-base";
import { AssetSettingService } from "../asset/configuration/asset-setting.service";
import { AssetUserService } from "../tenant/permissions/asset-user/asset-user.service";
import { SessionTokenService } from "./token/session-token.service";
import { SessionState } from "../../models/session/session-state.model";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AppOktaSessionHelperService } from "./app-okta-session-helper.service";
import { TenantService } from "../global/clients/tenant.service";
import { TenantModel } from "../../models/global/clients/tenant-model";
import { FlareStackModel } from "../../models/asset/emissions/flaring/flare-stack-model";
import { AssetModel } from "../../models/tenant/location/asset-model";
import { TokenModel } from "../../models/authentication/token.model";

@Injectable({
    providedIn: 'root'
})
export class AppOktaSessionService extends OptixComponentBase {

    private tokenService: SessionTokenService = inject(SessionTokenService);
    private tenantService: TenantService = inject(TenantService);
    private assetUserService: AssetUserService = inject(AssetUserService);
    private assetSettingService: AssetSettingService = inject(AssetSettingService);
    private appOktaSessionHelperService: AppOktaSessionHelperService = inject(AppOktaSessionHelperService);
    private router: Router = inject(Router);

    /**
     * The current state of the session
     */
    private readonly _sessionState = new BehaviorSubject<SessionState>(new SessionState());

    /**
     * Gets the current value of the session state
     * @returns The session state current value
     */
    public sessionState = (): SessionState => this._sessionState.getValue();

    /**
     * Gets the session state observable
     * @returns The session state observable
     */
    public sessionState$ = (): Observable<SessionState> => this._sessionState.asObservable();

    public requestedTenant: string = '';
    public requestedAsset: string | undefined = '';

    /**
     * Default constructor
     * @param _oktaStateService The okta state service
     * @param oktaAuth The okta authentication service
     */
    constructor(private _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {
        super();
        this.logDebug(this.constructor.name, 'App Okta Session Service Loaded!');
    }

    /**
     * Initialises the session
     * @param route The route to initialise the session for
     */
    public async init(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
        this.logDebug(this.init.name, 'OKTA Session init started');
        // Get the tenant and asset from the url
		var tenant = this.appOktaSessionHelperService.getTenantFromUrl(route);
        this.requestedTenant = tenant;
        var asset = this.appOktaSessionHelperService.getAssetFromUrl(route);
        this.requestedAsset = asset;
        // Get the session state
        var sessionState: SessionState = this.sessionState();
        if (sessionState.isSessionInitialised) {
			this.logDebug(this.init.name, 'Session already initialised and in progress');
            // If there is a url present, if not, ignore as it will have probably come from an OKTA token refresh
            if (route.url.length === 0) {
                this.logDebug(this.init.name, 'No URL present, ignoring');
                return;
            }
            // Check if the tenant or asset has changed
            if ((sessionState.currentTenant?.tenancyName === tenant) && (sessionState.currentAsset?.name === asset)) {
                this.logDebug(this.init.name, 'Session already initialised - tenant and asset not changed');
                return;
            }

            // If the asset has changed, just refresh the asset permissions
            if (sessionState.currentAsset?.name !== asset) {
                this.logDebug(this.init.name, 'Session already initialised - asset has been changed');
                //return this.initSessionForNewAsset(sessionState, route, tenant);
            }
		}
        // Initialise the session
        try {
            await this.initialiseSession(route, state, tenant, asset, sessionState);
        }
        catch(e) {
            this.logError(this.init.name, 'Error initialising session', [e]);
        }
        this.logDebug(this.init.name, 'OKTA Session init complete');
    }

    /**
     * Initialises the session
     * @param sessionState The current session state
     * @param route The route to initialise the session for
     * @param tenant The tenant to initialise the session for
     * @param asset The asset to initialise the session for
     */
    public async initialiseSession(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, tenant: string, asset: string | undefined, sessionState: SessionState | undefined): Promise<void> {
        this.logDebug(this.initialiseSession.name, 'Initialising session');
        let currentTenant: TenantModel | undefined = undefined;
        let currentAsset: AssetModel | undefined = undefined;
        // If the session state is not set, initialise a new session state
        if (!sessionState || !sessionState.isSessionInitialised) {
            sessionState = await this.initialiseSessionState();
            
            // Create the session token
            let token = await this.tokenService.generateAuthToken(sessionState.identityId, sessionState.b2cId, sessionState.email, tenant, undefined);
            // Update the session state with the token
            sessionState = this.appOktaSessionHelperService.updateSessionFromToken(sessionState, token, undefined, undefined);
            // Get the tenants for the user
            sessionState.tenants = await lastValueFrom(this.tenantService.getUsersTenants());
            this._sessionState.next(sessionState);
        }
        // Validate the tenant access
        currentTenant = this.validateTenantAccess(sessionState, tenant, route);

        // If the user has access to the tenant, select the tenant and get the assets
        if (currentTenant) {
            // Select the tenant
            await this.selectTenant(currentTenant);
            // Validate the asset access
            if (!this.appOktaSessionHelperService.isRequestedRouteForRootAdmin(route)) {
                currentAsset = this.validateAssetAccess(sessionState, asset);
            }
        }

        // If the user has access to the asset, set the asset
        if (currentAsset) {
            await this.selectAsset(currentAsset);
        }

        // Store the updated session state
        sessionState = this.sessionState();
        sessionState.isSessionInitialised = true;
        this._sessionState.next(sessionState);
        this.logDebug(this.initialiseSession.name, 'Session initialised');
        // Redirect the user if required
        this.redirectUser(route, state);
    }

    /**
     * Initialises the session state
     * @returns The session state
     */
    public async initialiseSessionState(): Promise<SessionState> {
        this.logDebug(this.initialiseSessionState.name, 'Initialising session state');

        // Get the claims and find the identity id claims
        let claims: any[] = await this.getClaims();
        let oktaId = claims.find(c => c.name === 'sub')?.value;
        let b2cId = claims.find(c => c.name === 'b2c')?.value;
        let email = claims.find(c => c.name === 'email')?.value;
        let name = claims.find(c => c.name === 'name')?.value;
        // Create a new session state
        let sessionState: SessionState = this.appOktaSessionHelperService.createSessionState(true, oktaId, b2cId, email);
        // If the name was found, split it into first and last name
        if (name) {
            let names = name.split(' ');
            sessionState.firstname = names[0];
            sessionState.lastname = names[1];
        }
        // Set the session state
        this._sessionState.next(sessionState);
        
        this.logDebug(this.initialiseSessionState.name, 'Session state initialised');
        return sessionState;
    }
    
    /**
     * Gets the claims from the Okta token
     * @returns Collection of claims from the Okta token
     */
    public async getClaims(): Promise<any[]> {
        const idToken: IDToken = await this.oktaAuth.tokenManager.get('idToken') as IDToken;
        return Object.entries(idToken.claims).map(entry => ({ name: entry[0], value: entry[1] }));
    }

    /**
     * Logs the user out.
     * @param postLogoutUri The url to redirect the user to after logout
     */
    public async logout(): Promise<void> {
        // Clear the session state
        this._sessionState.next(new SessionState());
        this._sessionState.complete();
        // Sign the user out
        this.oktaAuth.signOut();
    }

    /**
     * Validates the users access to the application and tenant
     */
    public validateTenantAccess(sessionState: SessionState, currentTenant: string, route: ActivatedRouteSnapshot): TenantModel | undefined {
        this.logDebug(this.validateTenantAccess.name, 'started validating tenant access');
        
        // If the user doesn't have any tenants, redirect them to unauthorised
        if (sessionState.tenants.length === 0) {
            this.logDebug(this.validateTenantAccess.name, 'user is not authorised to access any tenants');
            return undefined;
        }

        let authorisedTenant = sessionState.tenants.find(x => x.tenancyName.toLowerCase() === currentTenant.toLowerCase());
        if (authorisedTenant) {
            this.logDebug(this.validateTenantAccess.name, 'user authorised to access this tenant', [currentTenant]);
            return authorisedTenant;
        }
        
        // If the current route being requested is just to the root of the application
        if (route.url.length === 0) {
            this.logDebug(this.validateTenantAccess.name, 'application root requested');
            // there are multiple tenants, take them to the tenant selection screen since they are just accessing the root
            if (sessionState.tenants.length > 1) {
                this.logDebug(this.validateTenantAccess.name, 'user has multiple tenants, present selector');
            }
            else {
                this.logDebug(this.validateTenantAccess.name, 'user has only got access to a single tenant, set it as selected');
                return sessionState.tenants[0];
            }
        }
        
        return undefined;
    }

    /**
     * Validates the users access to the asset
     * @param assets The assets that the user has access to.
     */
    public validateAssetAccess(sessionState: SessionState, routeAsset: string | undefined): AssetModel | undefined {
        this.logDebug(this.validateAssetAccess.name, 'started validating asset access');
        // If the user doesn't have access to assets, take them to the no asset associations
        if (sessionState.assets.length === 0) {
            this.logDebug(this.validateAssetAccess.name, 'user is not authorised to access any assets');
            return undefined;
        }

        // Check if the asset is in the url
        if (!routeAsset) {
            return undefined;
        }

        let authorisedAsset = sessionState.assets.find(x => x.name.toLowerCase() === routeAsset?.toLowerCase());
        if (authorisedAsset === undefined) {
            this.logDebug(this.validateAssetAccess.name, 'user is not authorised to access this asset', [routeAsset]);
        }

        // Return the authorised asset
        return authorisedAsset;
    }

    public redirectUser(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
        this.logDebug(this.redirectUser.name, 'redirecting user if required', [route]);
        let sessionState = this.sessionState();
        // If there are no tenants, send to unauthorised
        if (sessionState.tenants.length === 0) {
            this.router.navigate(['unauthorised']);
            return;
        }
        // User has access to at least 1 tenant
        if (sessionState.currentTenant === undefined) {
            // Tenant has not been found, if they just went to the root of the application and have multiple tenants, direct the user to the tenant selector
            if (route.url.length === 0 && sessionState.tenants.length > 1) {
                this.router.navigate(['main', 'tenant-selector']);
                return;
            }
            // If the user is already being directed to the tenant selector, do not redirect them
            if (route.url.length === 1 && route.url[0].path === 'tenant-selector')
                return;

            // The user has attempted to go to a specific tenant, and/or has access to only 1 tenant, but that is not the tenant they are attempting to access
            this.router.navigate(['unauthorised']);
            return;
        }
        // If the user is attempting to access an admin page, check the admin access
        if (this.appOktaSessionHelperService.isRequestedRouteForRootAdmin(route))
            return;

        if (this.appOktaSessionHelperService.isRequestedRouteForSupport(route))
            return;

        // If the user is not attempting to get to an admin page or dashboard, check the asset access
        if ((!this.appOktaSessionHelperService.isRequestedRouteForAdmin(state) && !this.appOktaSessionHelperService.isRequestedRouteForDashboard(state)) || route.url.length === 0) {
            // If the user has no assets, take the user to no asset associations
            if (sessionState.assets.length === 0) {
                this.router.navigate(['main', sessionState.currentTenant.tenancyName, 'noassets']);
                return;
            }
            // If the current asset cannot be found in the url, direct the user to the asset dashboard
            if (sessionState.currentAsset === undefined) {
                this.router.navigate(['main', sessionState.currentTenant.tenancyName, 'dashboard']);
                return;
            }
        }
        this.logDebug(this.redirectUser.name, 'No redirecting required');
    }

    /**
     * Updates the session details from the token
     * @param tokenModel The token model details
     */
    public updateSessionFromNewToken(tokenModel: TokenModel, tenant: TenantModel | undefined, asset: AssetModel | undefined) {
        this.logDebug(this.updateSessionFromNewToken.name, 'Entering session update from token');
        let sessionState = this.sessionState();
        // Update the session state with the new token
        sessionState = this.appOktaSessionHelperService.updateSessionFromToken(sessionState, tokenModel, tenant, asset);
        this._sessionState.next(sessionState);
        this.logDebug(this.updateSessionFromNewToken.name, 'Exiting session update from token', [sessionState]);
    }

    /**
     * Updates the session to be an impersonated session
     * @param impersonatedUserSession The impersonated user session details
     */
    public initialiseImpersonationSession(impersonatedUserSession: SessionState) {
        this.logDebug(this.initialiseImpersonationSession.name, 'initialising impersonated session', [impersonatedUserSession]);
        this._sessionState.next(impersonatedUserSession);
        this.logDebug(this.initialiseImpersonationSession.name, 'initialised impersonated session', [impersonatedUserSession]);
    }

    /**
     * Sets the selected tenant and loads the assets for the tenant
     * @param tenant The selected tenant
     */
    public async selectTenant(tenant: TenantModel): Promise<AssetModel[]> {
        this.logDebug(this.selectTenant.name, 'Selecting tenant', [tenant]);
        let sessionState = this.sessionState();        
        // Get a new token for the asset
        var token = await this.tokenService.generateAuthToken(sessionState.identityId, sessionState.b2cId, sessionState.email, tenant.tenancyName, undefined);
        // Update the session state with the token
        sessionState = this.appOktaSessionHelperService.updateSessionFromToken(sessionState, token, tenant, undefined);
        this._sessionState.next(sessionState);
        // Get the assets for the tenant
        var assets = await lastValueFrom(this.assetUserService.getUsersAssets());
        this.updateAssets(assets);
        // Return the assets
        return assets;
    }

    /**
     * Sets the assets that the user has access to
     * @param assets the assets that the user has access to
     */
    public updateAssets(assets: AssetModel[]): void {
        this.logDebug(this.updateAssets.name, 'Updating assets', [assets]);
        let sessionState = this.sessionState();
        sessionState.assets = assets.filter(e => e.userAccessible);
        this._sessionState.next(sessionState);
    }

    /**
     * Sets the selected asset
     * @param asset The selected asset
     */
    public async selectAsset(asset: AssetModel, clearQueue: boolean = false): Promise<void> {
        this.logDebug(this.selectAsset.name, 'Selecting asset', [asset]);
        let sessionState = this.sessionState();        
        // Get the asset settings a new token with the users permissions for the asset
        var settings = await lastValueFrom(this.assetSettingService.getAllAssetSettings(asset.id, clearQueue));
        asset.assetSettingModels = settings;
        // Get a new token for the asset
        var token = await this.tokenService.generateAuthToken(sessionState.identityId, sessionState.b2cId, sessionState.email, sessionState.currentTenant == null ? '' : sessionState.currentTenant.tenancyName, asset.id);
        // Update the session state with the token
        sessionState = this.appOktaSessionHelperService.updateSessionFromToken(sessionState, token, sessionState.currentTenant, asset);
        this._sessionState.next(sessionState);
    }

    /**
     * Sets the current flare stack
     * @param currentFlareStack The flare stack selected
     */
    public setCurrentFlareStack(currentFlareStack: FlareStackModel): void {
        this.logDebug(this.setCurrentFlareStack.name, 'Setting current flare stack', [currentFlareStack]);
        let sessionState = this.sessionState();
        sessionState.currentFlareStack = currentFlareStack;
        this._sessionState.next(sessionState);
    }

    /**
     * Sets the acceptance of the terms and privacy
     */
    public setAcceptTermsAndPrivacy(): void {
        this.logDebug(this.setAcceptTermsAndPrivacy.name, 'Setting terms and privacy accepted');
        let sessionState = this.sessionState();
        sessionState.hasAcceptedPrivacy = true;
        this._sessionState.next(sessionState);
    }

    /**
     * Sets the theme in the session
     */
    public setTheme(newTheme: string): void {
        this.logDebug(this.setTheme.name, 'Setting new theme');
        let sessionState = this.sessionState();
        sessionState.theme = newTheme;
        this._sessionState.next(sessionState);
    }
}